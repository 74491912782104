import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="chartframe"
export default class extends Controller {
  connect() {
    console.log("Stimulus connected the Chart Frame controller"); 
  }

  btnClick(event) {
    var url = event.currentTarget.value;
    var frame = document.getElementById('chart-frame');
    frame.src = url;
  }
}
