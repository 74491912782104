import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="quick-pay"
export default class extends Controller {
  connect() {
    console.log("Quick pay controller connected")
  }

  doPay(e)
  {
    const publicId = e.target.dataset['id']
    const csrfToken = e.target.dataset['csrf']
    const amount = document.getElementById('pay_' + publicId).value

    fetch('/quick_pay', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ 
        'transaction': {
          'public_id': publicId,
          'amount': amount
        } 
      }) // body data type must match "Content-Type" header
  })
    .then(response => response.json())
    .then(data => {
      var divElement = document.getElementById('div_' + publicId)
      var dollars = new Intl.NumberFormat(`en-US`, {
        currency: `USD`,
        style: 'currency',
      }).format(amount);
      divElement.outerHTML = `Paid ${dollars}`

      divElement = document.getElementById('bal_' + publicId)
      dollars = new Intl.NumberFormat(`en-US`, {
        currency: `USD`,
        style: 'currency',
      }).format(data.balance);
      divElement.outerHTML = `${dollars}`
     })
  }
}
