import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="student-list"
export default class extends Controller {
  connect() {
    console.log("Stimulus connected the Student List controller"); 
  }

  groupSelectChange() {
    var groupSelect = document.getElementById('group-select');
    var url = "students/group/" + groupSelect.value + "/list";
    var frame = document.getElementById('student-list');
    frame.src = url;
  }
}
